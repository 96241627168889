<template>
    <!-- 搜索客户中心 -->
    <div style="padding: 20px 0">
        <div class="artifacts-manage-page">
            <div class="header-left">
                <div>
                    <el-date-picker v-model="value1" size="small" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" clearable>
                    </el-date-picker>
                </div>
                <div>
                    <el-autocomplete v-model="search.state2" style="width:400px" size="small"
                        :fetch-suggestions="querySearchAsync" placeholder="请输入内容" @select="handleSelect">
                    </el-autocomplete>
                </div>
                <div>
                    <el-button size="small" type="primary" @click="inquire">查询</el-button>
                </div>
            </div>

            <div class="artifacts-manage-table">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="artifactsManageDataList" border
                    align="center" style="width: 100%">
                    <el-table-column width="70" label="序号" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="公司名称" align="center"></el-table-column>
                    <el-table-column prop="nickname" label="用户昵称" align="center">
                    </el-table-column>
                    <el-table-column prop="o_user" label="用户账号" align="center"></el-table-column>
                    <el-table-column prop="o_ip" label="IP" align="center"></el-table-column>
                    <el-table-column prop="o_mac" label="mac标识" align="center"></el-table-column>
                    <el-table-column prop="o_starttime" label="开始时间" align="center"></el-table-column>
                    <el-table-column prop="o_endtime" label="结束时间" align="center"></el-table-column>
                    <el-table-column prop="o_duration" label="使用时长" align="center"></el-table-column>
                </el-table>
            </div>
            <div style="text-align: center; margin-top: 30px" v-if="artifactsManageDataList.length > 0">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page.page" :page-sizes="[10, 30, 40, 50]" :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.count">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
import { enterprise_list, online_list } from '@/common/js/search.js'
export default {
    data() {
        return {
            value1: '',
            restaurants: [],
            // 表格数据
            artifactsManageDataList: [],
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            // 搜索
            search: {
                time1: '',
                time2: '',
                state: '',
                state2: ''
            },
        };
    },
    created() {
        this.init1();
    },
    methods: {
        inquire() {
            if (this.value1 != null) {
                this.search.time1 = Date.parse(this.value1[0]).toString().substring(0, 10);
                this.search.time2 = Date.parse(this.value1[1]).toString().substring(0, 10);
            } else {
                this.search.time1 = ''
                this.search.time2 = ''
            }
            if (this.search.state == '') {
                this.search.state2 = ''
            }
            this.init()
        },
        // 匹配输入内容
        querySearchAsync(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
            // console.log(results);
            results.forEach(res => {
                res.value = res.name
            })
            cb(results);
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.name.includes(queryString));
            };
        },
        handleSelect(item) {
            this.search.state = item.id
        },
        inputSearch(value) {
            if (value == '') {
                this.search.state = ''
            }
        },
        // 序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 查询
        init() {
            let dt = {
                page: this.page.page,
                limit: this.page.pageSize,
                start_time: this.search.time1 == 'NaN' ? '' : this.search.time1,
                end_time: this.search.time2 == "NaN" ? '' : this.search.time2,
                e_id: this.search.state2 == '' ? '' : this.search.state
            };
            online_list(dt).then(res => {
                let rs = res.result
                this.artifactsManageDataList = rs.data
                this.page.count = rs.total
            });
        },
        init1() {
            enterprise_list().then(res => {
                this.restaurants = res.result
            });
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
.artifacts-manage-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;

    .artifacts-manage-table {
        margin-top: 20px;
    }
}

.header-left {
    display: flex;
    align-items: center;

    div {
        margin-right: 10px;
    }
}
</style>
