import $http from '../../utils/http.js';

/**
 * 企业列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function enterprise_list(data) {
    return $http.get("/enterprise_list", data, 'enterprise_list');
}

/**
 * 在线时长列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function online_list(data) {
    return $http.get("/online_list", data, 'online_list');
}
